<template>
    <div class="bbb">
        <div class="Informed">
                <h4 style="text-align: center;" class="agreementtitle">互联网诊疗风险告知及知情同意书</h4>
                <div>
                    <div class="text-title">互联网诊疗服务潜在风险告知</div>
                    <div class="text">
                        &nbsp;&nbsp;&nbsp;接受互联网诊疗服务可能出现潜在风险，有些不常见的风险未能一一列出，如果换做有疑问应与医生讨论。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;受限于互联网诊疗本身的局限性（如医生不能面诊、触诊等，无法通过相关的诊查手段及检查、检验结果准确判断病情的进展），医生给出的本次诊疗方案、健康管理方案、远程医疗方案依赖于用户所上传的资料和描述的症状，以及既往的病例资料、临床诊断。如前述信息不准确或不全面，将对本次诊疗方案的合理制定产生一定的影响。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;提供互联网诊疗服务的医生，可能来自于实体医疗机构中的不同级别、性质的医院，执业年限及经验不一。互联网医院的分诊主要依赖于用户的需求尽力匹配适合用户的医生为患者进行服务。用户可以自行选择医生，但受限于医生的执业经验及背景，医生给诊疗方案的合理性和先进性可能存在差异。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;由于精神心理疾病本身的特殊性和复杂性，患者本身的体质状况，及现有医疗水平条件的限制等，都存在可能发生各种并发症和危害自身生命健康的意外风险。由于精神心理疾病本身的复杂性，以及诊疗措施疗效出现的延后性，诊疗方案、健康管理方案、远程医疗方案可能不会达到患者期许的效果，且有些疾病或并发症是不可根治的，需要患者积极配合，长期坚持治疗，才能延缓疾病的进展。医生已经尽力为患者制定合理的方案，致力减少药物治疗不良反应的发生，但不可能完全避免，且不可预测，需要在患者的配合下，并根据临床情况不断调整方案。

                        疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配。如果患者未完全遵守和配合，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。

                        配送药品可能受到物流时效的影响，导致患者延时服用药物，或患者正在用药物或者手术等治疗其他疾病，也可能存在延时用药、联合用药等风险。

                        医生主要解决本专业领域的医疗问题，非本专业的疾病需要到其它专业科室进行诊治或接受远程医疗服务，互联网线上诊疗服务对持续维护健康具有必要性，如患者难以提供全部既往的诊疗资料，则可能导致健康档案在使用时因资料收集不全而存在潜在风险。

                        互联网医院对患者信息按要求采取了保密措施，但泄密的可能性依然不能完全消除。

                        患者的医疗文件和诊疗数据，独属于医疗机构，可能被用于科学研究（包括但不限于大数据研究），患者并不能从中直接受益。
                    </div>
                    <div class="text-title">用户知情同意确认：</div>
                    <div class="text">
                        用户确认在互联网医院上问诊的疾病，已经在实体医疗机构明确诊断，患者已经填写或上传相关的病历资料，愿意互联网诊疗。<br>

                        用户确认既往发生过与本次发病类似的常见病、慢性病病症，并曾经在实体医院诊疗，但现在无法提供与本次病症相关的检诊资料。患者愿意接受互联网医院提供的线上诊疗服务，并协助医生完善健康档案，达成互联网诊疗。<br>

                        用户确认愿意接受医生根据诊疗经验为患者安排的远程医疗服务。<br>

                        用户确认已经知晓并同意以上内容，理解相关的风险，愿意接受互联网医院的服务以及接受疾病诊疗服务，并签署知情同意书。<br>

                        用户确认未得到服务结果会百分之百成功的许诺。<br>

                        您同意您的诊疗内容在去除姓名、头像、出生日期等信息后将设置为默认展示，医生给您的指导建议同时也会帮助其他相似情况的用户。
                    </div>
                </div>
        </div>
    </div>
  </template>

<script>
     export default {
        data(){
            return {
                illustratedShow:true
            }
        }
     }
</script>
<style scoped>
.Informed{
    padding-bottom: 10% !important;
    height: 91vh !important;
    overflow-y: scroll;
}
</style>
 
