<template>
  <div class="bbb Drugs" id="pres">
    <van-nav-bar title="统筹问诊申请" left-arrow @click-left="onClickLeft" />
    <van-guide :imgList="imgList" v-if="guideshow" @end="end" />
    <div class="page-wrapper">
      <div
        class="jiuzhenren card-wrap"
        :class="patient.name == '' && 'require'"
      >
        <h4 class="title ">就诊人信息</h4>

        <div class="con">
          <div class="jiuzhenren_xuanzhe">
            <div @click="deletePatient">
              <span class="patient-text">{{ patient.name }} </span>
              <i class="el-icon-delete" v-if="patient.name !== ''"></i>
            </div>

            <span v-if="patient.PresCheck" class="required_success">
              <i class="el-icon-check"></i> 已授权
            </span>
            <el-button
              v-else
              class="btn-icon"
              @click="AuthPresUrl"
              icon="el-icon-paperclip"
              type="text"
              >请进行医保授权</el-button
            >
          </div>
        </div>
      </div>

      <div
        class="achives card-wrap"
        style="height: auto"
        v-if="patient.PresCheck && Achives.length !== 0"
      >
        <h4 class="title">就诊人档案</h4>

        <van-swipe :autoplay="0" :height="180" @change="PatientFilesonChange">
          <van-swipe-item
            v-for="(item, index) of Achives"
            @click="handleImagePreview(item.pres_info.UrlImgs)"
          >
            <div class="achives-list-item" :class="return_achive_txt(item) == '取消选中' && 'achives_active'">
              <div class="achives-list-item-con">
                <img :src="item.pres_info.UrlImgs[0]" class="image" />
                <div class="info">
                  <span class="Achiveshospital_name">
                    {{ item.pres_info.hospital_name }}
                  </span>
                  <time class="time">
                    {{ item.pres_info.patient_disease.join(',') }}
                  </time>
                </div>
              </div>
              <div class="operate">
                <el-button  plain @click.stop="deleteAchives(item)"
                  >删除档案</el-button
                >

                <el-button type="primary" @click.stop="selectPatientFiles"
                  >{{  return_achive_txt(item)}}</el-button
                >
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <!-- <van-button v-for="(item,index) of Achives_disease" :key="index" plain type="primary" border-color="transparent">{{item.name}}</van-button> -->
        <div v-if="Achives_disease.length !== 0" class="selected-disease-wrap">
          所有档案：
        <div
          v-for="(item, index) in Achives_disease"
          :key="index"
          class="tag"
          :class="Achives_disease_ids.some(disease => disease.id === item.id && disease.name === item.name) ? '' : 'tagshow'"
          @click="handleClose(item)"
        >
          {{ item.name }}
        </div>
      </div>
      </div>
      <!-- tagshow -->
      <div class="lsyy card-wrap" :class="Drugs.length == 0 && 'require'">
        <h4 class="title label">
          历史用药

          <van-button
            v-if="Drugs.length !== 0"
            icon="disease-bank"
            size="mini"
            @click="drugsShow = true"
          >
            药品详情
          </van-button>
        </h4>

        <div class="con">
          <div class="lsyy_xuanzhe">
            <div
              class="text"
              v-for="(item, index) of Drugs"
              style="padding-bottom: 5px; padding-left: 16px"
            >
              <div>
                {{ item.name }}
                <span style="color: #000"> × {{ item.number }}</span>
              </div>
            </div>

            <div class="btn" @click="yaopionxuanze('Drugs')">
              添加药品
              <van-icon name="arrow" color="#D6D6D6" />
            </div>
          </div>
        </div>
      </div>

      <div class="jzxx card-wrap">
        <h4 class="title">就诊信息</h4>

        <van-cell-group
          @click.stop
          text
          borders="false"
          style="padding: 0 3px; position: relative"
        >
          <div
            v-if="CheckInquiry.suggest !== ''"
            class="CheckInquiry"
            :class="CheckInquiry.suggest == '通过' ? 'agree' : 'refuse'"
          >
            <span>{{ CheckInquiry.suggest + '：' }}</span>
            <span>{{ CheckInquiry.title }}</span>
          </div>

          <van-cell
            class="qzjb"
            is-link
            value="请选择确诊疾病"
            value-class="place"
            @click="disease('disease')"
          >
            <template slot="title">
              <div
                class="flex-align"
                :class="diseaseList.length == 0 && 'require'"
              >
                <span class="label">确诊疾病 </span>
              </div>
            </template>
          </van-cell>

          <div class="qzjb-list">
            <div
              v-for="(item, index) in diseaseList"
              :key="item.title + index"
              class="qzjb-item"
            >
              {{ item.title }}
            </div>
          </div>

          <div class="line" />

          <van-cell value-class="flex-align-end">
            <template slot="title">
              <div class="flex-align" :class="conditions == '' && 'require'">
                <span class="label">当前病情描述 </span>
              </div>
            </template>
          </van-cell>
          <van-field
            class="van-field-textarea"
            type="textarea"
            v-model="conditions"
            @input="conditions = conditions.replace(regStr, '')"
            placeholder="发病时间、主要症状、治疗过程、目前状况等.如用药后有不良反应请说明"
            rows="2"
            autosize
            maxlength="200"
            show-word-limit
          />
          <div class="symptom_auxiliary" v-if="symptomList.length !== 0">
            我有点：<el-tag
              v-for="tag in symptomList"
              :key="tag"
              @click="symptomCli(tag)"
              :type="symptomType(tag)"
            >
              {{ tag }}
            </el-tag>
          </div>

          <div class="line margin_top" />

          <!-- 复诊证明 -->

          <van-cell value-class="flex-align-end">
            <template slot="title">
              <div
                class="flex-align"
                :class="presDownUrl.length == 0 && 'require'"
              >
                <span class="label">复诊证明 （单张图片限制大小3M） </span>
              </div>
            </template>
          </van-cell>
          <van-uploader
            :max-count="4"
            :preview-full-image="false"
            @click-preview="handleImagePreview"
            v-model="presImgUrl"
            multiple
            :after-read="afterRead"
            :before-read="beforeRead"
            @delete="imgdelete"
          >
          </van-uploader>
          <p class="text pading10" style="line-height: 22px">
            上传化验单、检查资料、问诊单、患处照片或其他疾病描述相关资料(最多4张)
          </p>

          <div class="line margin_top" />

          <van-cell value-class="flex-align-end">
            <template slot="title">
              <div
                class="flex-align"
                :class="firstMedinsName.length > 3 ? '' : 'require'"
              >
                <span class="label">首诊/线下就诊医院名称 </span>
              </div>
            </template>
          </van-cell>
          <van-field
            class="firstMedinsName"
            v-model="firstMedinsName"
            label-align="left"
            input-align="left"
            placeholder="请输入首诊/线下就诊医院名称"
          >
            <template #right-icon>
              <img class="icon" src="@/assets/images/edit-blue.png" />
            </template>
          </van-field>

          <div class="line" />

          <!-- 过往疾病史 -->
          <van-cell
            class="gwjbs"
            title="过往疾病史"
            is-link
            v-bind="diseasefun('past', '请选择确诊疾病')"
            @click="disease('past')"
          />
        </van-cell-group>
      </div>

      <div
        class="other card-wrap"
        :style="{
          height: activeNames.length ? 'auto' : '0',
          paddingBottom: activeNames.length ? '' : '0.81rem',
        }"
      >
        <h4
          class="title"
          @click="
            activeNames.length == 0 ? (activeNames = ['1']) : (activeNames = [])
          "
        >
          <div style="flex: 1">婚育、过敏、高血压及其他</div>

          <van-icon
            :name="activeNames.length == 0 ? 'arrow' : 'arrow-down'"
            color="#d6d6d6"
          />
        </h4>

        <div class="checkbox-group">
          <div
            v-for="tag in [
              '已育',
              '未育',
              '预备1年内育儿',
              '患有高血压',
              '患有糖尿病',
              '抽烟',
              '喝酒',
              '血脂异常',
            ]"
            :key="tag"
            class="tag"
            :class="[PresFild.includes(tag) && 'active']"
            @click="
              () => {
                PresFild.includes(tag)
                  ? (PresFild = PresFild.filter((el) => el != tag))
                  : PresFild.push(tag);
              }
            "
          >
            {{ tag }}
          </div>
        </div>

        <van-cell-group>
          <van-cell
            title="家族疾病史"
            @click="disease('family')"
            is-link
            v-bind="diseasefun('family')"
          />

          <div class="line" />

          <van-field
            type="textarea"
            v-model="allergy"
            label="过敏情况"
            placeholder="请输入过敏情况"
            rows="2"
            autosize
            maxlength="50"
            show-word-limit
          />
        </van-cell-group>
      </div>

      <div class="agree-wrap">
        <van-checkbox
          v-model="re_diagnosis"
          icon-size="25px"
          class="consent margin_top"
          checked-color="#1557FF"
        >
          <span class="consent">我是</span>
          <span @click="illustratedShow = true">
            <span class="consent_color">复诊</span>
            ， 有
            <span class="consent_color">既往史</span>
            ，我已阅读
            <span @click.stop="InformedShow = true" class="consent_color">
              知情同意书</span
            >
          </span>
        </van-checkbox>
      </div>

      <div class="submit-btn" @click="CheckInquiry_get">开始申请问诊</div>
    </div>



    <van-popup
      v-model="illustratedShow"
      position="top"
      closeable
      :overlay="true"
    >
      <div>
        <illustrated />
        <div class="m-top">
          <van-button
            @click="illustratedShow = false"
            color="linear-gradient(to right, #02d3d9, #09e0c0)"
            type="primary"
            block
            >返 回</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="InformedShow" position="top" closeable :overlay="true">
      <div>
        <Informed />
        <div class="m-top Informed_btn">
          <van-button
            @click="InformedShow = false"
            color="linear-gradient(to right, #02d3d9, #09e0c0)"
            type="primary"
            block
            >返 回</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 药品详情 -->
    <van-action-sheet
      v-model="drugsShow"
      title="已选择历史药品"
      @cancel="ScreeningDrugs"
      @click-overlay="ScreeningDrugs"
    >
      <div v-for="(item, index) of Drugs" class="bpd margin_top">
        <div style="display: flex">
          <div style="width: 100px">
            <van-image
              v-if="hasParameters(item.imgurl)"
              width="100"
              height="100"
              :src="item.imgurl"
            />
            <img
              v-else
              style="width: 90px; height: 80px"
              src="../../assets/image/Drugs.webp"
              alt=""
            />
          </div>
          <div style="width: 0; flex-grow: 1">
            <div style="font-size: 16px; padding: 1px 5px">
              {{ item.name }}
            </div>
            <div style="font-size: 16px; padding: 1px 5px">
              规格：{{
                item.PublicDrugs.length !== 0 ? item.PublicDrugs[0].specs : ''
              }}
            </div>
            <div style="font-size: 12px; padding: 1px 5px">
              生产企业：{{
                item.PublicDrugs.length !== 0
                  ? item.PublicDrugs[0].manufacturingEnterprise
                  : ''
              }}
            </div>
            <div style="font-size: 12px; padding: 1px 5px">
              国药准字：{{
                item.PublicDrugs.length !== 0
                  ? item.PublicDrugs[0].approvalNumber
                  : ''
              }}
            </div>
            <div style="font-size: 12px; padding: 1px 5px">
              医保编码：{{
                item.PublicDrugs.length !== 0
                  ? item.PublicDrugs[0].drugcode
                  : ''
              }}
            </div>
            <div class="price_number">
              
              <div @click.stop style="padding: 0 10px">
                <van-stepper
                  input-width="20px"
                  button-size="20px"
                  v-model="item.number"
                  min="0"
                  :max="item.maxNumber"
                />
                <van-button
                  type="danger"
                  @click="drugsdefault(item, index)"
                  size="mini"
                  >删除</van-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <el-drawer
      :visible.sync="Presdrawer"
      direction="btt"
      :show-close="true"
      :wrapperClosable="false"
    >
      <div class="textCenter" v-if="activeName == '0'">
        <p class="text-style margin-top10">
          正在连线快诊医生接诊<i class="el-icon-loading"></i>
        </p>
        <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
        <el-progress
          :format="format"
          class="margin-top10"
          type="circle"
          :text-inside="false"
          :percentage="ProgressPres"
        ></el-progress>
        <!-- <p class="margin-top10 border" @click="activeName = '3';">不连线了，等待医院排班医生</p> -->
      </div>
      <div class="textCenter" v-if="activeName == '2'">
        <p class="text-style margin-top10">快诊医生</p>
        <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
        <p class="margin-top10 PresbtnFontSize">
          <el-button @click="afterPres" icon="el-icon-notebook-2" type="primary"
            >继续等待快诊医生</el-button
          >
        </p>
        <!-- <p class="margin-top10" >{{reckonbytime}}秒后自动选择去医院排队</p>   -->
      </div>
      <div class="textCenter" v-if="activeName == '3'">
        <p class="text-style margin-top10" v-if="PresInfo.doctorId !== 'None'">
          {{ `快诊医生已接诊` }}
        </p>
        <p v-else class="text-style margin-top10">
          {{ `由医院安排医生接诊` }}
        </p>
        <p class="margin-top10">请支付订单后马上和医生沟通</p>
        <p class="margin-top10 Pres0btn">
          <el-button
            @click="YBPayImg(PresInfo.pres_id)"
            v-if="PresInfo.pres_id !== 'None'"
            icon="el-icon-bank-card"
            type="primary"
          >
            支付诊费</el-button
          >
          <el-button
            @click="pay_action"
            v-else
            icon="el-icon-bank-card"
            type="primary"
          >
            {{
              PresInfo.pres_id !== 'None' ? '支付诊费' : '申请问诊'
            }}</el-button
          >
        </p>
      </div>
    </el-drawer>
    <footer_ />
  </div>
</template>
<script>
import footer_ from '../../components/footer.vue';
import base from '.././api/base.js';
import illustrated from '../components/illustrated';
import Informed from '../components/Informed';
import mixin from '@/utils/mixin';
import img from '../../assets/js/img.js';
import { compressImage } from '../../assets/js/CompressImageUtils.js';
import { ImagePreview } from 'vant-green';
import shuiyin from '../../assets/image/shuiyin.png';
export default {
  mixins: [mixin],
  components: {
    footer_,
    illustrated,
    Informed,
  },
  data() {
    return {
      guideshow:false,
      imgList: [shuiyin],
      activeNames: [],
      regStr:
        /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi,
      Drugs: [],
      drugsShow: false,
      patient: {
        id: '',
        name: '',
        PresCheck: false,
      },
      firstMedinsName: '',
      diseaseList: [],
      CheckInquiry: {
        suggest: '',
        title: '',
      },

      conditions: '', //描述
      symptomList: [],
      symptomSelected: [],
      PatientFilesonIndex: 0,

      presDownUrl: [], // 复诊证明
      presImgUrl: [],

      past: [],
      family: [],

      PresFild: [],

      PatientFiles: {
        pres_infoArray: [],
      },

      allergy: '',

      re_diagnosis: false,
      pastHistory: false,
      checked: false,
      illustratedShow: false,
      PharmacyInfo: {},
      price: 0,
      Presdrawer: false,
      activeName: '0',
      ProgressPres: 0,
      InformedShow: false,

      Achives: [],
      consultType: 1,
      Achives_disease:[],
      Achives_disease_ids:[]
    };
  },
  methods: {
    handleClose(item){
      let diseaseList = JSON.parse(JSON.stringify(this.diseaseList));
      let Achives_disease_ids = JSON.parse(JSON.stringify(this.Achives_disease_ids));
      const index = Achives_disease_ids.findIndex(disease => disease.id === item.id && disease.name === item.name);
      if (index !== -1) {
        Achives_disease_ids.splice(index, 1); // 如果存在，移除
        diseaseList = diseaseList.filter(disease => disease.id !== item.id || disease.title !== item.name);
      } else {
        Achives_disease_ids.push(item); // 如果不存在，添加
        diseaseList.push({title:item.name,id:item.id});
        
        
      }
      
      this.Achives_disease_ids = Achives_disease_ids;
      this.PatientFilesonChange(this.Achives.findIndex(element=>{return element.id == item.id}))
      this.selectPatientFiles()
      this.diseaseList = diseaseList;
    },
    selectAchivesDisease(){
      let item = this.Achives[this.PatientFilesonIndex];
      item.pres_info.UrlImgs.forEach(url => {
        if (!this.presImgUrl.some(img => img.content === url)) {
          this.presImgUrl.push({ content: url, status: null, file: {}, url });
        }
      });
    },
    selectPatientFiles(state = true) {
      let item = this.Achives[this.PatientFilesonIndex];
      // 检查并添加或删除 presDownUrl 数据
      if (!this.presDownUrl.includes(...item.pres_info.imgs)) {
        this.presDownUrl.push(...item.pres_info.imgs);
      } 
      // 检查并添加或删除 presImgUrl 数据
      const newPresImgUrls = item.pres_info.UrlImgs.map((element) => {
        return { content: element, status: null, file: {}, url: element };
      });
      newPresImgUrls.forEach(newUrl => {
        const existingIndex = this.presImgUrl.findIndex(url => url.content === newUrl.content);
        if (existingIndex === -1) {
          this.presImgUrl.push(newUrl);
        } else {
          this.presImgUrl.splice(existingIndex, 1);
        }
      });
      
      // 检查并设置或清除 firstMedinsName
      if (this.firstMedinsName !== item.pres_info.hospital_name) {
        this.firstMedinsName = item.pres_info.hospital_name || '';
      } else {
        this.firstMedinsName = '';
      }
      
      this.$message({
        message: '操作成功',
        type: 'success',
      });
    },
    changeAchiveshandle(){
      const arr = this.Achives.flatMap(element => 
        element.pres_info.patient_disease.map(item => ({
          id: element.id,
          name: item
        }))
      );
      this.Achives_disease = arr
    },
    // 图片预览 判断是否在档案中 如果在 就不让预览
    handleImagePreview(item) {
      this.guideshow = true
      this.ImagePreviewFun(
        {
          pres_info:{
            UrlImgs:item.content ? [item.content] : item
          }
        }
      )
    },
    return_achive_txt(item){
      if (this.presDownUrl.some(url => item.pres_info.imgs.includes(url))) {
        return '取消选中';
      } else {
        return '选择档案';
      }
    },
    deletePatient() {
      // 删除互联网就诊人
      // patient
      let that = this;
      this.$confirm(
        `当前姓名（${this.patient.name}）与您实际名字不匹配？点击删除后，重新获取`,
        '更正就诊人',
        {
          confirmButtonText: '更正就诊人',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        let data = {
          pharmacy_id: sessionStorage.getItem('pharmacy_id'),
          user_id: JSON.parse(localStorage.getItem('user')).user_id,
        };
        that.$api.article
          .InternetHospitalGet(
            {
              search_db: 'DelPatient',
              ih_type: localStorage.getItem('ih_type'),
            },
            data
          )
          .then((res) => {
            if (res.data.code == '200') {
              that.loadList();
            }
          });
      });
    },
    ImagePreviewFun(item) {
      this.instance  = ImagePreview(
        {
          images:item.pres_info.UrlImgs,
          asyncClose: true
        }
      );
    },
    end(){
      this.guideshow = false
      this.instance.close();
    },
    deleteAchives(item) {
      this.$confirm('是否确认删除此档案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.Delete('Achives', 'id', item.id, { deleted: 1 });
        this.loadList()
      });
    },
    ScreeningDrugs() {
      let Drugs = this.Drugs.filter((item) => {
        return item.number > 0;
      });
      this.Drugs = Drugs;
    },
    PatientFilesonChange(index) {
      if(index ==-1){
        return
      }
      this.PatientFilesonIndex = index;
    },
    
    async beforeRead(file) {
      if (this.presDownUrl.length >= 4) {
        this.$message({
          message: '最多上传4张复诊证明',
          type: 'warning',
        });
        return false;
      }
      return true;
    },
    AuthPresUrl() {
      this.$api.article
        .InternetHospitalGet(
          {
            search_db: 'AuthPresUrl',
            ih_type: localStorage.getItem('ih_type'),
          },
          {
            user_id: JSON.parse(localStorage.getItem('user')).user_id,
          }
        )
        .then((res) => {
          const h = this.$createElement;
          this.$msgbox({
            title: '医保授权',
            message: h('p', { style: 'text-align: center' }, [
              h('span', null, '当前就诊人尚未获得医保授权'),
              h('br'),
              h('i', { style: 'font-weight: 800' }, '请点击“授权”按钮进行授权'),
              h('br'),
              h('i', null, '授权完成后，'),
              h('i', { style: 'font-weight: 400' }, '请再次进入此页面'),
            ]),
            showCancelButton: true,
            confirmButtonText: '授权',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action == 'confirm') {
                window.location.href = res.data.result.data.presUrl;
              } else {
                done();
              }
            },
          });
        });
    },
    //申请处方
    async CheckInquiry_get() {
      if (this.patient.PresCheck == false) {
        this.$message({
          message: '请授权就诊人',
          type: 'warning',
        });
        return;
      }
      if (this.Drugs.length == 0) {
        this.$message({
          message: '请选择药品',
          type: 'warning',
        });
        return;
      }

      if (this.diseaseList.length == 0) {
        this.$message({
          message: '请选择疾病',
          type: 'warning',
        });
        return;
      }
      if (this.conditions == '') {
        this.$message({
          message: '请输入病情描述',
          type: 'warning',
        });
        return;
      }
      console.log(this.presDownUrl)
      if (this.presDownUrl.length == 0) {
        this.$message({
          message: '请上传复诊证明文件',
          type: 'warning',
        });
        return;
      }

      if (this.firstMedinsName < 4) {
        this.$message({
          message: '请输入首诊/线下就诊医院',
          type: 'warning',
        });
        return;
      }
      if (!this.re_diagnosis) {
        this.$message({
          message: '请确保您是复诊,且有既往史，且已阅读知情同意书',
          type: 'warning',
        });
        return;
      }
      let Drugs = [];
      this.Drugs.forEach((element) => {
        Drugs.push(element.PublicDrugs[0].id);
      });
      let RecentDrugs = await this.$api.article.RecentDrugs({ drugs: Drugs });
      if (RecentDrugs.data.code == '-2') {
        return;
      }
      for (let i = 0; i < this.presDownUrl.length; i++) {
        let inspectProve = await this.$api.article.img_info(
          this.presDownUrl[i],
          false
        );
        if (inspectProve.data.code == '-2') {
          return;
        }
      }
      const h = this.$createElement;
      let messageList = [];
      this.Drugs.forEach((element) => {
        messageList.push(
          h(
            'i',
            { style: 'font-weight: 800' },
            `${element.name} × ${element.number}`
          )
        );
        messageList.push(h('br'));
      });
      this.$msgbox({
        title: '您当前选择的历史用药为',
        message: h('p', { style: 'text-align: center' }, messageList),
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            const loading = this.$loading({
              lock: true, //lock的修改符--默认是false
              text: '正在为您的问诊进行用药检查', //显示在加载图标下方的加载文案
              color: 'rgba(255, 255, 255, 1)',
              spinner: 'el-icon-loading', //自定义加载图标类名
              background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
              target: document.querySelector('#table'), //loadin覆盖的dom元素节点
            });
            setTimeout(() => {
              loading.close();
            }, 10000);
            this.postPres();
          }
          done();
        },
      });
    },
    //创建 处方
    async postPres() {
      let medicine = [];
      let DRGDatabase_name = [];
      this.diseaseList.forEach((element) => {
        DRGDatabase_name.push({ name: element.title });
      });
      let Drugs = this.Drugs;
      if (
        Drugs.every((medicine) => medicine.PublicDrugs[0].class_ab === '丙类')
      ) {
        this.$message({
          message: '您申请的药品，均不能参与报销，故不需要申请统筹报销。',
          type: 'success',
        });
        return;
      }
      Drugs.forEach((element) => {
        medicine.push({
          skuId: element.PublicDrugs[0].id,
          total: `${element.number}`,
        });
      });
      let texts = [
        `医生你好，我是${this.patient.name}，`,
        // `医生你好，我是${this.patient.name}， 性别：${this.patient.sex == 1 ? '男' : '女'}，年龄：${this.patient.age}，`,
        // `性别：${this.patient.sex == 1 ? '男' : '女'}`,
        // `年龄：${this.patient.age}，`,
        // `我的历史用药为：${this.returnStr(this.Drugs,'name',true)}，`,
        // `确诊的疾病为：${this.returnStr(this.diseaseList,'title')}，`,
        // `当前病情为：${this.conditions.replace('我有点','')}`,
        // `过往的疾病史为：${  this.returnStr(this.past,'title')  }，`,
        // `过敏情况为：${this.allergy == '' ? '无' : this.allergy}，我是复诊，请尽快帮我开问诊，谢谢`,
        // `我是复诊`,
        `请尽快帮我问诊，谢谢`,
      ];
      let pres_body = {
        presInfo: this.pres_body,
        Drugs: this.DrugsSieveRemoval(this.Drugs),
        Current: this.Current,
        diseaseList: this.diseaseList,
        past: this.past,
        allergy: this.allergy,
        family: this.family,
        PresFild: this.PresFild,
        patient: this.patient,
        text: JSON.stringify(texts),
      };
      let data = {
        created: {
          ih_type: localStorage.getItem('ih_type'),
          patientId: this.patient.id,
          consultType: this.consultType,
          price: this.price,
          conditions: this.conditions,
          historyContent: '无',
          allergyContent: '无',
          birthContent: '无',
          isSmoking: this.PresFild.indexOf('吸烟') == -1 ? 0 : 1,
          isDrink: this.PresFild.indexOf('喝酒') == -1 ? 0 : 1,
          isFeritin: this.PresFild.indexOf('患有高血压') == -1 ? 0 : 1,
          isDislipidemia: this.PresFild.indexOf('血脂异常') == -1 ? 0 : 1,
          isDiabetes: this.PresFild.indexOf('患有糖尿病') == -1 ? 0 : 1,
          firstMedinsName: this.firstMedinsName,
          imgs: JSON.stringify(this.presDownUrl),
          familyhis: '无',
          pres_body: JSON.stringify(pres_body),
          medicine: JSON.stringify(medicine),
          pharmacy_id: this.PharmacyInfo.id,
          if_dispatch: 1,
          DRGDatabase_name: JSON.stringify(DRGDatabase_name),
        },
      };

      this.post_pres(data, 'afterPres');
    },
    post_pres(data, funName) {
      const loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: '正在为您选择更快的互联网医院为您申请问诊', //显示在加载图标下方的加载文案
        color: 'rgba(255, 255, 255, 1)',
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
        target: document.querySelector('#table'), //loadin覆盖的dom元素节点
      });
      setTimeout(() => {
        loading.close();
      }, 10000);
      setTimeout(() => {
        let params = {
          search_db: 'Pres',
        };
        this.$api.article.created(params, data).then((res) => {
          loading.close();
          if (res.data.code && res.data.code == '200') {
            this.PresInfo = res.data.result;
            this[funName]();
          } else {
            this.$message('开具问诊失败！');
          }
        });
      }, 1000);
    },
    afterPres() {
      this.Presdrawer = true;
      let that = this;
      this.activeName = '0';
      this.ProgressPres = 100;
      let timeSetInterval = 5;
      clearInterval(that.timeSetInterval);
      this.timeSetInterval = setInterval(function (res) {
        timeSetInterval = timeSetInterval - 1;
        if (that.activeName != '0') {
          clearInterval(that.timeSetInterval);
        }
        if (that.ProgressPres == 0) {
          clearInterval(that.timeSetInterval);
          that.afterWait();
        } else {
          that.ProgressPres = that.ProgressPres - 4;
          that.apireturn('Pres', 'id', that.PresInfo.id).then((res) => {
            that.PresInfo = res.data.result;
            if (res.data.result.doctorId !== 'None') {
              that.activeName = '3';
              that.pay_action(false);
              clearInterval(that.timeSetInterval);
            } else {
              that.$api.article.get_doctor(res.data.result.id);
            }
            if (timeSetInterval == 0) {
              that.$api.article.get_doctor(res.data.result.id);
            }
          });
        }
      }, 1000);
    },
    afterWait() {
      this.activeName = '2';
      let that = this;
      this.reckonbytime = 10;
      this.timeSetInterval = setInterval(function (res) {
        if (that.activeName != '2') {
          clearInterval(that.timeSetInterval);
        }
        if (that.reckonbytime == 0) {
          that.activeName = '3';
          clearInterval(that.timeSetInterval);
        } else {
          that.reckonbytime = that.reckonbytime - 1;
        }
      }, 1000);
    },
    pay_action(JumpState = true) {
      this.loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: '正在为您申请问诊', //显示在加载图标下方的加载文案
        color: 'rgba(255, 255, 255, 1)',
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
        target: document.querySelector('#table'), //loadin覆盖的dom元素节点
      });
      if (this.PresInfo.doctorId !== 'None') {
        //医生接单
        this.created_order(this.PresInfo.doctorId, this.PresInfo.id, true);
      } else {
        this.apireturn('Pres', 'id', this.PresInfo.id).then((res) => {
          this.PresInfo = res.data.result;
          this.loading.close();
          if (res.data.result.doctorId == 'None') {
            this.$api.article.get_doctor(this.PresInfo.id).then((res) => {
              if (
                Array.isArray(res.data.result) &&
                res.data.result.length !== 0
              ) {
                this.doctorInfo = res.data.result[0];
                this.created_order(this.doctorInfo.id, this.PresInfo.id);
              } else {
                this.loading.close();
                this.$confirm('当前暂无可用医生, 是否继续?', '提示', {
                  confirmButtonText: '继续等待',
                  cancelButtonText: '取消等待',
                  type: 'warning',
                })
                  .then(() => {
                    this.afterPres();
                  })
                  .catch(() => {});
              }
            });
          } else {
            this.created_order(this.PresInfo.doctorId, this.PresInfo.id);
          }
        });
      }
    },
    format(percentage) {
      return percentage / 4 + '秒';
    },
    onClickLeft() {
      // sessionStorage.removeItem('Drugs');
      // sessionStorage.removeItem('Current');
      // sessionStorage.removeItem('disease');
      // sessionStorage.removeItem('post_pres_info');
      this.$router.back(-1);
    },
    yaopionxuanze(type) {
      this.$router.push({
        name: 'yaopinxuanzhe',
        query: {
          type: type,
        },
      });
      sessionStorage.setItem('Drugs', JSON.stringify(this.Drugs));
      sessionStorage.setItem(
        'post_pres_info',
        JSON.stringify({
          presDownUrl: this.presDownUrl,
          presImgUrl: this.presImgUrl,
          firstMedinsName: this.firstMedinsName,
          conditions:this.conditions
        })
      );
    },
    loadList() {
      this.Achives = []
      if (sessionStorage.getItem('index')) {
        this.loading = this.$loading({
          lock: true,
          text: '正在为你选择更快的互联网医院',
          color: 'rgba(255, 255, 255, 1)',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          target: document.querySelector('#table'),
        });
      }
      this.$api.article
        .InternetHospitalGet(
          {
            search_db: 'Patient',
            ih_type: localStorage.getItem('ih_type'),
          },
          {
            user_id: JSON.parse(localStorage.getItem('user')).user_id,
          }
        )
        .then((res) => {
          if (sessionStorage.getItem('index')) {
            this.loading.close();
            sessionStorage.removeItem('index');
          }
          if (res.data.result.data) {
            this.patient = {
              id: res.data.result.data.id,
              name: res.data.result.data.name,
              sex: res.data.result.data.sex,
              age: res.data.result.data.age,
              PresCheck: res.data.result.data.PresCheck,
            };
            this.renew_pres()
            if (res.data.result.data.PresCheck) {
              
              this.$api.article
                .query2(
                  {
                    search_db: 'Achives',
                    query_type: 'equal',
                  },
                  {
                    query: [
                      {
                        fild: {
                          found_id: localStorage.getItem('userrole_id'),
                          name: this.patient.name,
                          project_share: JSON.parse(
                            localStorage.getItem('PharmacyInfo')
                          ).project_id,
                          state: 'disable',
                        },
                        type: '=',
                        condition: '&&',
                      },
                    ],
                  }
                )
                .then((res) => {
                  let Achives = res.data.result;
                  let that = this;
                  if (Achives.length !== 0) {
                    this.apireturn(
                      'Project',
                      'id',
                      JSON.parse(localStorage.getItem('PharmacyInfo'))
                        .project_id
                    ).then((res) => {
                      let ProjectInfo = res.data.result;
                      this.$confirm(
                        `您在${ProjectInfo.title}诊所就诊过,存在${Achives.length}条就诊记录，是否授权尚云方使用`,
                        '提示',
                        {
                          distinguishCancelAndClose: true,
                          confirmButtonText: '授权',
                          cancelButtonText: '取消',
                        }
                      )
                        .then(() => {
                          // 创建更新请求的数组
                          const updatePromises = Achives.map((element) => {
                            let params = {
                              search_db: 'Achives',
                              query_type: 'equal',
                            };
                            let data = {
                              query: {
                                id: element.id,
                              },
                              update: {
                                state: 'enable',
                              },
                            };
                            return this.$api.article.update(params, data);
                          });

                          // 等待所有请求完成
                          Promise.all(updatePromises)
                            .then(() => {
                              this.getAchives(that.patient.id, that.patient.name);
                            })
                            .catch((error) => {
                              console.error('更新失败', error);
                            });
                        })
                        .catch((action) => {
                          this.getAchives(that.patient.id, that.patient.name);
                        });
                    });
                  } else {
                    this.getAchives(that.patient.id, that.patient.name);
                    this.getAchives(that.patient.id, that.patient.name,false);
                  }
                });
            }
          } else {
            this.patient = {
              id: '',
              name: '',
              PresCheck: false,
            };
          }
        });
       
      
    },
    getAchives(patient_id, name,project_share = true) {
      this.$api.article
        .query2(
          {
            search_db: 'Achives',
            query_type: 'equal',
          },
          {
            query: [
              {
                fild: {
                  found_id: localStorage.getItem('userrole_id'),
                  name: name,
                  project_share: project_share ?  JSON.parse(
                    localStorage.getItem('PharmacyInfo')
                  ).project_id : '',
                  state: 'enable',
                },
                type: '=',
                condition: '&&',
              },
            ],
          }
        )
        .then((res) => {
          let Achives = res.data.result;
          Achives.forEach((element) => {
            if (element.pres_info == 'None') {
              return;
            }
            let UrlImgs = [];
            element.pres_info = JSON.parse(element.pres_info);
            element.pres_info.imgs.forEach((item) => {
              UrlImgs.push(
                `${base.baseUrl}img?static_path=Pres&file_name=${item}&token=${
                  JSON.parse(localStorage.getItem('userinfo')).token
                }&userrole_id=${localStorage.getItem('userrole_id')}`
              );
            });
            element.pres_info['UrlImgs'] = UrlImgs;
          });
         
          this.Achives = this.Achives.concat(Achives);
          this.changeAchiveshandle()
          console.log(this.Achives)
          let AchivesArr = [];
          this.diseaseList.forEach((element) => {
            this.Achives.forEach((item) => {
              if (
                item.pres_info.patient_disease
                  .join('')
                  .indexOf(element.title) !== -1
              ) {
                AchivesArr.push(element.title);
              }
            });
          });
          if (AchivesArr.length !== 0) {
            if (!sessionStorage.getItem('AchivesMessage')) {
              const h = this.$createElement;
              this.$msgbox({
                title: '提示',
                message: h('p', { style: 'text-align: center' }, [
                  h('span', null, `您选择的下列疾病已存在就诊档案,`),
                  h('br'),
                  h('span', { style: 'color: teal' }, AchivesArr.join(',')),
                  h('br'),
                  h('i', null, `可使用档案更方便`),
                ]),
                confirmButtonText: '确定',
                beforeClose: (action, instance, done) => {
                  done();
                  sessionStorage.setItem('AchivesMessage', '0');
                },
              });
            }
          }
        });


   
    },
    renew_pres(){
      if(sessionStorage.getItem('renew_pres_id')){
        if(!this.patient.PresCheck){
          this.$message({
            message: '请先授权就诊人信息后，进行续方',
            type: 'warning'
          });
          return
        }
          let no_drugs = []
          this.apireturn('Pres','id',sessionStorage.getItem('renew_pres_id')).then(res=>{
            if(res.data.result.id){
              let data  = res.data.result
              data.pres_body = JSON.parse(data.pres_body)
              let medicine = JSON.parse(data.medicine)
              let pres_body = data.pres_body
              let Drugs_data = []
              let promises = medicine.map((element) => {
                return this.apireturn('PublicDrugs','id',element.skuId).then(Drugs_info => {
                  if(Drugs_info.data.result.id){
                    Drugs_info.data.result['number'] = element.total*1
                    Drugs_info.data.result['PublicDrugs'] = [JSON.parse(JSON.stringify( Drugs_info.data.result))]
                    return this.$api.article.query2({
                      search_db:'Drugs',
                      query_type:'first'
                    },{
                      query:[{fild:{publicdrugs_id:element.skuId,pharmacy_id:JSON.parse(localStorage.getItem('PharmacyInfo')).drugs_id},type:'=',condition:'&&'}]
                    }).then(res=>{
                      if(!res.data.result.id){
                        no_drugs.push(Drugs_info.data.result)
                      }
                      return Drugs_info.data.result
                    })
                  }
                })
              })
              
              Promise.all(promises).then(results => {
                Drugs_data = results.filter(result => result !== undefined)
                
                Drugs_data = Drugs_data.filter(drug => !no_drugs.some(noDrug => noDrug.id === drug.id));
                const h = this.$createElement;
                this.$msgbox({
                  title: '提示',
                  message: h('p', null, [
                    h('span', { style: 'color: red' }, no_drugs.map(item=>item.name).join(', ')),
                    h('br'),
                    h('i', null, '上述药品在当前药店尚未添加，是否续方'),
                  ]),
                  showCancelButton: true,
                  confirmButtonText: '继续续方',
                  cancelButtonText: '重新选择药品',
                  beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                      done();
                    } else {
                      done();
                    }
                  }
                })
                sessionStorage.setItem('Drugs',JSON.stringify(Drugs_data))
                sessionStorage.setItem('drugSelected',JSON.stringify(Drugs_data))
                sessionStorage.setItem('disease',JSON.stringify(pres_body.diseaseList))
                let presImgUrl = JSON.parse(data.imgs)
                let post_pres_info = {
                  conditions:data.conditions,
                  firstMedinsName:data.firstMedinsName,
                  presDownUrl:presImgUrl,
                  presImgUrl:presImgUrl.map((element) => {
                    return { content: this.img_url('Pres',element), status: null, file: {}, url: element };
                  })
                }
                sessionStorage.setItem('post_pres_info',JSON.stringify(post_pres_info))
                this.putInfo()
                sessionStorage.removeItem('renew_pres_id')
              })
            }
          })
      }
    },
    disease(type) {
      this.$router.push({
        name: 'disease',
        query: {
          type: type,
        },
      });
      sessionStorage.setItem('Drugs', JSON.stringify(this.Drugs));
      sessionStorage.setItem(
        'post_pres_info',
        JSON.stringify({
          presDownUrl: this.presDownUrl,
          presImgUrl: this.presImgUrl,
          firstMedinsName: this.firstMedinsName,
          conditions:this.conditions
        })
      );
    },
    getPharmacy() {
      this.$api.article
        .query(
          {
            search_db: 'Pharmacy',
            query_type: 'first',
          },
          {
            query: {
              id: sessionStorage.getItem('pharmacy_id'),
            },
          }
        )
        .then(async (res) => {
          localStorage.setItem('drugs_id', res.data.result.drugs_id);
          this.PharmacyInfo = res.data.result;
        });
    },
    //更新信息
    putInfo() {
      this.Drugs = sessionStorage.getItem('Drugs')
        ? JSON.parse(sessionStorage.getItem('Drugs'))
        : [];
      let promises = [];
      let DiseaseSymptomDrug = [];
      this.price = 0;
      this.Drugs.forEach((item) => {
        if (item.price !== 'None') {
          this.price += item.price * 1 * item.number;
        }
        if (item.PublicDrugs.length !== 0) {
          item[
            'imgurl'
          ] = `${base.weburl}static/img/${item.PublicDrugs[0].img_url}`;
        }
        let params = {
          search_db: 'DiseaseSymptomDrug',
          query_type: 'like',
        };

        let data = {
          query: {
            drug: item.name,
          },
        };

        let promise = this.$api.article.query(params, data).then((res) => {
          res.data.result.forEach((element) => {
            DiseaseSymptomDrug.push(element);
          });
        });

        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        let symptomList = [];
        DiseaseSymptomDrug.forEach((element) => {
          element.symptom.split(',').forEach((item) => {
            if (symptomList.indexOf(item) == -1 && symptomList.length <= 10) {
              symptomList.push(item);
            }
          });
        });
        this.symptomList = symptomList;
      });
      // 更新疾病信息
      this.diseaseList = sessionStorage.getItem('disease')
        ? JSON.parse(sessionStorage.getItem('disease'))
        : [];
      let PresFild = [];
      this.diseaseList.forEach((element) => {
        if (element.title.indexOf('高血压') !== -1) {
          PresFild.push('患有高血压');
        } else if (element.title.indexOf('糖尿病') !== -1) {
          PresFild.push('患有糖尿病');
        }
      });
      this.PresFild = PresFild;
      this.past = sessionStorage.getItem('past')
        ? JSON.parse(sessionStorage.getItem('past'))
        : [];

      if (sessionStorage.getItem('post_pres_info')) {
        let { presDownUrl, presImgUrl, firstMedinsName,conditions } = JSON.parse(
          sessionStorage.getItem('post_pres_info')
        );
        this.presDownUrl = presDownUrl;
        console.log(this.presDownUrl)
        this.conditions = conditions;
        this.presImgUrl = presImgUrl;
        this.firstMedinsName = firstMedinsName;
      }

    },
    symptomType(name) {
      if (this.symptomSelected.indexOf(name) == -1) {
        return 'success';
      } else {
        return 'info';
      }
    },
    symptomCli(name) {
      if (this.symptomSelected.indexOf(name) == -1) {
        this.symptomSelected.push(name);
      } else {
        this.symptomSelected.splice(this.symptomSelected.indexOf(name), 1);
      }
      this.conditions =
        this.symptomSelected.length !== 0
          ? `我有点${this.symptomSelected.toString()}`
          : '';
      this.conditions = this.conditions.replace(/,/g, '，');
    },
    ifOtc() {
      //判断是否药品都是otc药品
      return this.Drugs.length == 0
        ? false
        : this.Drugs.every((item) => {
            return item.PublicDrugs[0].otc == '1';
          });
    },
    async afterRead(file, detail) {
      if (file[0].file.size > 3072000) {
        for (let i = 0; i < file.length; i++) {
          let element = file[i];
          for (let i = 3000; i >= 0; i -= 500) {
            let max = i;
            let compressInfo = await compressImage(element.content, max);
            if (compressInfo.size < 3072000) {
              let Blob = compressInfo;
              let formData = new FormData();
              formData.append('file', Blob, '123456.png');
              this.$api.article.upload('Pres', formData).then((res) => {
                if (res.data.code == '200') {
                  this.presDownUrl.push(res.data.result.file_name);
                  this.img_info(res.data.result.file_name, true);
                  element.status = null;
                }
              });
              break;
            }
          }
        }
      } else {
        file.forEach((element) => {
          let Blob = this.base64ToBlob(element.content);
          let formData = new FormData();
          formData.append('file', Blob, '123456.png');
          this.$api.article.upload('Pres', formData).then((res) => {
            if (res.data.code == '200') {
              this.presDownUrl.push(res.data.result.file_name);
              this.img_info(res.data.result.file_name, true);
              element.status = null;

            }
          });
        });
      }
    },
    img_info(file_name, check) {
      this.$api.article.img_info(file_name, check);
    },
    base64ToBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    imgdelete(file, detail) {
      this.presDownUrl.splice(detail.index, 1);
    },
    diseasefun(data, place) {
      let str = '';
      this[data].forEach((element) => {
        str += element.title;
      });
      if (str.length >= 10) {
        str = this[data][0].title + '...';
      }
      return {
        valueClass: str ? '' : 'place',
        value: str || place,
      };
    },
    returnStr(data, key, DrugsState = false) {
      let str = '';
      if (data.length == 0) {
        str = '无';
      }
      for (var i = 0; i < data.length; i++) {
        if (DrugsState) {
          str += `${data[i][key]}（${data[i].PublicDrugs[0].specs}×${data[i].number}`;
          if (
            data[i].PublicDrugs[0].dosage_txt !== '' &&
            data[i].PublicDrugs[0].dosage_txt !== 'None'
          ) {
            str += `用法用量：（${data[i].PublicDrugs[0].dosage_txt}）`;
          }
          if (
            data[i].PublicDrugs[0].indication !== '' &&
            data[i].PublicDrugs[0].indication !== 'None'
          ) {
            str += `,适应症：（${data[i].PublicDrugs[0].indication}）`;
          }
        } else {
          str += data[i][key];
        }
        if (i < data.length - 1) {
          str += ',';
        }
      }
      return str;
    },
    DrugsSieveRemoval(Drugs) {
      let DrugsS = [];
      Drugs.forEach((element) => {
        DrugsS.push({
          name: element.name,
          id: element.id,
          PublicDrugs: [
            {
              name: element.PublicDrugs[0].name,
              id: element.PublicDrugs[0].id,
              otc: element.PublicDrugs[0].otc,
            },
          ],
        });
      });
      return DrugsS;
    },
    getGenderFromIdCard(idCard) {
      //// 判断性别
      var genderDigit = idCard;
      // 判断性别
      if (genderDigit % 2 === 0) {
        return '2';
      } else {
        return '1';
      }
    },
    //发起互联网医院问诊
    created_order(doctorId, presID, JumpState = true) {
      let that = this;
      setTimeout(() => {
        that.loading.close();
        if (!that.create_pres_success) {
          this.$message.error('申请问诊失败，请重新申请');
          that.create_pres_success = false;
        }
      }, 50000);
      this.apireturn('Pres', 'id', presID).then((res) => {
        if (res.data.result.pres_id == 'None') {
          let data = {
            doctor_id: doctorId,
            pres_id: presID,
          };
          const loading = this.$loading({
            lock: true, //lock的修改符--默认是false
            text: '加载中...', //显示在加载图标下方的加载文案
            color: 'rgba(255, 255, 255, 1)',
            spinner: 'el-icon-loading', //自定义加载图标类名
            background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
            target: document.querySelector('#table'), //loadin覆盖的dom元素节点
          });
          setTimeout(() => {
            loading.close();
          }, 10000);
          this.$api.article.CreatedInternetPres(data).then((res) => {
            this.create_pres_success = true;
            loading.close();
            if (res.data.code != '-2') {
              this.PresInfo = res.data.result;
              if (JumpState) {
                this.YBPayImg(res.data.result.pres_id);
              } else {
                this.loading.close();
              }
            } else {
              const h = this.$createElement;
              this.$msgbox({
                title: '提示',
                message: h('p', null, [
                  h('span', null, '问诊申请不成功,不正常原因为:'),
                  h('i', { style: 'color: teal' }, `${res.data.text}`),
                ]),
                confirmButtonText: '确定',
                beforeClose: (action, instance, done) => {
                  done();
                  // if(action == 'confirm'){
                  //     created_order(doctorId,presID,JumpState = true)
                  // }else{
                  //     done();
                  // }
                },
              });
            }
          });
        } else {
          if (JumpState) {
            this.YBPayImg(res.data.result.pres_id);
          } else {
            this.loading.close();
          }
        }
      });
    },
    // 跳转支付连接
    YBPayImg(pres_id) {
      if (this.PresInfo.doctorId == 'None') {
        this.$message.error('暂无可用医生');
        return;
      }
      let that = this;
      setTimeout(() => {
        that.loading.close();
      }, 10000);
      this.$api.article
        .YBPayImg(pres_id, 1, localStorage.getItem('ih_type'))
        .then((res) => {
          this.$alert('请支付成功后重新进入问诊', '提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              sessionStorage.removeItem('Drugs');
              sessionStorage.removeItem('Current');
              sessionStorage.removeItem('disease');
              sessionStorage.removeItem('patientId');
              if (res.data.result.data.slice(0, 4) == 'http') {
                this.loading.close();
                window.location.href = res.data.result.data;
              } else {
                this.loading.close();
                that.$router.push('/message');
              }
            },
          });
        });
    },
    drugsdefault(item, index) {
      this.Drugs.splice(index, 1);
      let drugSelected = JSON.parse(sessionStorage.getItem('drugSelected'));
      drugSelected.splice(index, 1);
      sessionStorage.setItem('drugSelected', JSON.stringify(drugSelected));
    },
  },
  computed: {
    hasParameters(imageUrl) {
      return function (url) {
        return /img\/(.+)/.test(url);
      };
    },
  },
  created() {
    if(!sessionStorage.getItem('pharmacy_id')){
      this.$message.error('请先扫描药店二维码，再进行处方申请');
      this.$router.push('/')
      return
    }
    this.loadList();
    this.getPharmacy();
    this.putInfo();
    if (this.$route.query.consultType) {
      this.consultType = this.$route.query.consultType;
    }
  },
};
</script>
<style lang="less" scoped>
.achives_active{
  background: rgb(237,243,252);
  border-radius: 6px;
}
.van-nav-bar__title {
  color: #111 !important;
  font-weight: 600 !important;
}

.bbb {
  min-height: 100vh;
  background: linear-gradient(to bottom, #eef4fd, #f6f6f6);
}

.card-wrap {
  margin-bottom: 0.69rem;
}

.require .label::after {
  color: #e52c2c !important;
}

.card-wrap {
  padding: 1.03rem 0.81rem;
  min-height: 5.25rem;
  background: #ffffff;
  border-radius: 0.69rem;

  & .label::after {
    content: '(必填)';
    margin-left: 0.63rem;
    margin-bottom: 0.15rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 0.56rem;
    color: #67c23a;
    align-self: flex-end;
  }

  .title {
    font-family: SourceHanSansCN;
    font-weight: bold;
    font-size: 0.94rem;
    color: #282828;

    display: flex;
    align-items: center;

    &::before {
      content: '';
      margin-right: 0.38rem;

      width: 0.19rem;
      height: 0.81rem;
      background: #1557ff;
      border-radius: 0.09rem;
    }
  }

  .con {
    padding: 1rem 0.1rem 0 0.44rem;
  }
}

.line {
  border-bottom: 0.06rem solid #d3d8e0;

  &.margin_top {
    margin-top: 1.34rem !important;
  }
}

.page-wrapper {
  padding: 0.5rem 0.6rem 4rem;

  .jiuzhenren {
    &_xuanzhe {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .patient-text {
        font-family: AlibabaPuHuiTi_2_65_Medium;
        font-weight: 400;
        font-size: 0.88rem;
        color: #5a5a5a;
        line-height: 1.31rem;
      }

      .el-button {
        padding-top: 0;
        padding-bottom: 0;
      }

      .btn-icon {
        color: #f56c6c;
      }
    }
  }

  .lsyy {
    position: relative;
    padding-right: 0.97rem;

    .title .van-button {
      position: absolute;
      right: 0.81rem;
    }

    .con {
      padding-top: 0;
    }

    &_xuanzhe {
      display: flex;
      flex-direction: column;

      align-items: flex-end;

      .btn {
        margin-top: 1rem;
        color: #d6d6d6;
        display: flex;
        align-items: center;

        .van-icon {
          margin-left: 0.38rem;
        }
      }

      .text {
        width: 100%;
        padding: 0.6rem 0 0.3rem;

        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .jzxx {
    ::v-deep .van-hairline--bottom::after {
      border-bottom-width: 0;
    }

    .van-cell {
      padding-left: 0.59rem;

      &::after {
        display: none;
      }
    }

    .required {
      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.56rem;
      color: #e52c2c;
    }

    .van-field-textarea {
      margin: 0 0.4rem;

      ::v-deep .van-field {
        background: #f6f7fb;
        border-radius: 0.34rem;
      }
    }

    .qzjb {
      padding-top: 1.03rem;
      padding-right: 0;

      .place span,
      .van-icon::before {
        color: #d6d6d6;
      }
    }

    .qzjb-list {
      margin-bottom: 0.75rem;
      padding: 0 0.44rem;

      display: flex;
      flex-wrap: wrap;
      gap: 0.53rem;

      .qzjb-item {
        min-width: 6.19rem;
        height: 2.59rem;
        background: #f6f8ff;
        border-radius: 0.34rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .achives {
    .achives-list-item {
      padding: 0.75rem 0.44rem;

      &-con {
        display: flex;
        align-items: center;
      }

      .image {
        width: 9.53rem;
        height: 5.25rem;
        display: block;
      }

      .info {
        margin-left: 1.03rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .Achiveshospital_name {
          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 0.94rem;
          color: #3d3d3d;
        }

        .time {
          margin-top: 0.72rem;
          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 0.81rem;
          color: #a5a5a5;
        }
      }
      .operate {
        margin-top: 1.22rem;
        display: flex;
        justify-content: flex-end; 

        .el-button--default{
          margin-left: 1.66rem;
          padding: 0;
          width: 6.71rem;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.31rem;
          font-weight: 500;
          font-size: 0.75rem;
        }

        .el-button--primary {
          margin-left: 1.66rem;
          padding: 0;
          background-color: #396cef;
          border-color: #396cef;

          width: 6.71rem;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.31rem;

          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }

  .firstMedinsName {
    ::v-deep .van-field {
      padding-top: 0;
      padding-right: 0;
      padding-left: 1.31rem;

      &::after {
        display: none;
      }
    }

    .icon {
      width: 0.72rem;
      height: 0.72rem;
    }
  }

  .gwjbs {
    padding-right: 0;

    .place {
      span {
        color: #d6d6d6;
      }
    }

    .van-icon::before {
      color: #d6d6d6;
    }

    &::after {
      display: none;
    }
  }

  .other {
    padding-bottom: 1.59rem;
    min-height: 3.34rem;
    overflow: hidden;

    .title {
      justify-content: space-between;
    }

    .checkbox-group {
      padding: 1.56rem 0.78rem 0;

      .tag {
        position: relative;
        padding: 0 1.19rem;
        margin: 0 0.66rem 0.47rem 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-family: SourceHanSansCN;
        font-weight: 400;
        font-size: 0.88rem;
        color: #a2a2a2;

        height: 2.41rem;
        background: #f2f2f2;
        border-radius: 0.34rem;

        overflow: hidden;

        &.active {
          color: #5382ec;
          background: rgba(64, 158, 255, 0.08);

          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;

            width: 1.78rem;
            height: 1.25rem;

            background-color: #588fde;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0.04rem;
            right: 0.3rem;

            width: 0.48rem;
            height: 0.96rem;

            border-color: #fff;
            border-style: solid;
            border-width: 0 0.18rem 0.18rem 0;
            transform: rotate(45deg) scale(0.6);
          }
        }
      }
    }

    .van-cell-group,
    .van-cell,
    ::v-deep .van-hairline--bottom {
      &::after {
        border-bottom-width: 0 !important;
      }
    }

    .van-cell {
      padding-right: 0;

      .van-icon::before {
        color: #d6d6d6;
      }

      ::v-deep &.van-field {
        margin: 0 0.31rem 0 0.84rem;
        padding: 0.8rem 0.7rem;
        width: auto;
        background: #f6f7fb;
        border-radius: 0.34rem;
      }
    }
  }

  .agree-wrap {
    padding-left: 0.31rem;

    .van-checkbox {
      margin-top: 0.88rem;

      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.88rem;
      color: #282828;
    }
  }

  .submit-btn {
    margin: 1.38rem 0 2.03rem;
    height: 3.53rem;
    background: #1557ff;
    border-radius: 0.31rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 1.13rem;
    color: #ffffff;
  }
}

.consent_color {
  color: #1557ff;
}
.Informed_btn{
  position: fixed;
  bottom: 10px;
  width: 90%;
  left: 5%;
}
</style>
<style lang="less">
.selected-disease-wrap {
  margin-top: 1.06rem;
  padding: 0 0 0 0.81rem;

  font-family: SourceHanSansCN;
  font-weight: 500;
  font-size: 0.94rem;
  color: #000000;
  line-height: 1.38rem;
  .tagshow{
      &::before{
        display: none !important;
      }
      &::after{
        display: none !important;
      }
    }
  .tag {
    position: relative;
    padding: 0 1.16rem;
    margin: 0 0.5rem 0.5rem 0;
    background: rgba(64, 158, 255, 0.08);
    border-radius: 0.31rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-family: SourceHanSansCN;
    font-weight: 400;
    font-size: 0.88rem;
    color: #5382ec;

    height: 2.41rem;
    background: #e3effd;
    border-radius: 0.34rem;

    overflow: hidden;
   
    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;

      width: 1.78rem;
      height: 1.25rem;

      background-color: #588fde;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0.04rem;
      right: 0.3rem;

      width: 0.48rem;
      height: 0.96rem;

      border-color: #fff;
      border-style: solid;
      border-width: 0 0.18rem 0.18rem 0;
      transform: rotate(45deg) scale(0.6);
    }
  }
}
</style>

